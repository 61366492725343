@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./theme/fonts";

@layer base {
  :root {
    --font-sans: "Geist", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;

    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;

    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;

    --primary: 243.4 0.75 59%;
    --primary-foreground: 0 0% 98%;

    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;

    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;

    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;

    --ring: 240 5% 64.9%;

    --radius: 0.5rem;
  }
}

body {
  font-family: "Geist", sans-serif;
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

div,
footer,
header,
section {
  box-sizing: border-box;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.redesign .ant-table-cell-row-hover {
  background-color: white !important;
}

.redesign .ant-table-thead .ant-table-cell {
  background: #F8FAFC !important;
}

.ant-switch {
  background-color: gray;
}

.tab {
  .tab-icon {
    opacity: 0;
  }

  &:hover {
    .tab-icon {
      opacity: 1;
    }
  }
}

.ant-table-cell {
  overflow: hidden;
}

.ant-table-selection-column {
  border-right: none !important;
}

.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}

.ant-drawer-close svg {
  color: #FFFFFF;
}

.redesign .ant-upload-drag .ant-upload {
  padding: 0 !important;
}

.postProcessingItems {
  .ant-select-status-error {
    .ant-select-selector {
      border-color: #d9d9d9 !important;
    }
  }

  .ant-input-status-error {
    border-color: #d9d9d9 !important;
  }
}

.ev-ant {
  .ant-select-selection-placeholder {
    color: rgb(60, 60, 60) !important;
  }
}

.company-logo .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}